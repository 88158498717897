<template>
  <div class="content">
    <div class="flex center lastPageContent">
      <div class="w-40 ">
        <img class="mb-60" src="@/assets/img/logo/image3.png" alt="">
        <div class="mb-25 tx-40">Submitted successfully</div>
        <div class="tx-20 mb-40">
          Thanks for your kindly feedback, we'll contact you as soon as possible!
        </div>
        <a href="/firstPage" class="tx-20">BACK TO HOME PAGE</a>
      </div>
    </div>
    <div class="footerBox flex space-around alignCenter">
      <div class="footerItem flex center">
        <div class="tx-center">
          <img
            class="mb-40"
            src="@/assets/img/logo/LOGO+chasingai.svg"
            alt=""
          />
          <div class="tx-20">+0065 97941186</div>
        </div>
      </div>
      <div class="footerItem flex center">
        <div class="tx-center">
          <img
            class="mb-20"
            src="@/assets/img/logo/BusinessCooperation.png"
            alt=""
          />
          <div class="footerTitle">Business Cooperation</div>
          <div class="tx-20">JoeRao@chasingai.com</div>
        </div>
      </div>
      <div class="footerItem flex center">
        <div class="tx-center">
          <img
            class="mb-20"
            src="@/assets/img/logo/ChannelPartnership.png"
            alt=""
          />
          <div class="footerTitle">Channel Partnership</div>
          <div class="tx-20">AlexLiu@chasingai.com</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {};
</script>

<style>
.content {
  width: 100%;
  color: #333333;
}

.lastPageContent {
  width: 100%;
  height: 100vh;
  text-align: center;
}

.footerBox {
  width: 100%;
  height: 54vh;
  background: #030c2d;
}

.footerItem {
  width: 27%;
  height: 58.3%;
  background: rgba(29, 53, 137, 1);
  color: #fff;
}

.footerTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 30px;
}


</style>